<template>
  <div class="nav">
    
    <img alt="Vue logo" src="./assets/logo.png">
    <div class="links">
      <h3>Share Files</h3>
      <div style="display: flex; flex-direction: column; margin-left: 10px; padding: 0;">
        <p style="margin: 0; padding: 0;">Go to</p>
        <button ref="changePage" @click="changePage" id="changePage" class="down">Download</button>
      </div>
    </div>
  </div>
  <div class="container">
    <UploadScreen  ref="uploadScreen" id="uploadScreen"></UploadScreen>
    <DownloadScreen style="display: none;" ref="downloadScreen"  id="downloadScreen"></DownloadScreen>
  </div>
  
</template>
<script>
import DownloadScreen from './components/DownloadScreen.vue';
import UploadScreen from './components/UploadScreen.vue';

export default {
  data(){
    return {

    }
  },
  methods: {
    changePage(event){
      if(event.target.innerHTML == "Download"){
        document.getElementById("changePage").innerHTML = "Upload";
        document.getElementById("changePage").setAttribute("class", "up");
        document.getElementById("downloadScreen").style = "display:block";
        document.getElementById("uploadScreen").style = "display:none";
      }else {
        document.getElementById("changePage").innerHTML = "Download";
        document.getElementById("changePage").setAttribute("class", "down");
        document.getElementById("downloadScreen").style = "display:none";
        document.getElementById("uploadScreen").style = "display:block";
      }
    }
  },
  name: 'App',
  components: {
    UploadScreen,
    DownloadScreen
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
#downloadScreen{
  width: 350px;
  height: 250px;
  border: #152bd6 1px solid;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 50px;
}
#uploadScreen{
  width: 350px;
  height: 250px;
  border: #138721 1px solid;
  padding: 10px;
  border-radius: 10px;
}

.nav {
  position: fixed;
  background: #afafaf;
  width: 100%;
  height: 50px;
  top: 0;
  margin: 0;
  padding-left: 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;

}

.nav > img {
  width: 40px;
  height: 40px;
}

.links {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: end;
}

.links > div > button {
  height: 50%;
  width: 75px;
  margin-left: 10px;
  margin-right: 5px;
  padding: 3px;
}

.down {
  color: #ffffff;
  background: #138721;
  border: #2a9337 1px solid;
  border-radius: 5px;

}

.down:hover {
  color: #ffffff;
  background: #2a9337;
  border: #b8dbbc 1px solid;
  border-radius: 5px;
}

.up {
  color: #ffffff;
  background: #152bd6;
  border: #4355de 1px solid;
  border-radius: 5px;

}

.up:hover {
  color: #ffffff;
  background: #2c40da;
  border: #5b6ae2 1px solid;
  border-radius: 5px;
}
</style>
