import axios from "axios";
const baseURL = "https://trialapp.site/api/v1";
const server = axios.create({
    headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
    },
    baseURL: baseURL
})

export default server;
